import { useSelector } from "react-redux";
import BlackScreen from "./pages/BlackScreen";
import Countdown from "./pages/Countdown";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import LightShow from "./pages/LightShow";
import EndScreen from "./pages/EndScreen";

const StateRouter = ({ subscribe }) => {
  const step = useSelector((state) => state.stateMachine.step);

  const stepComponents = {
    home: <Home />,
    signup: <Signup />,
    waiting: <Countdown />,
    countdown: <Countdown />,
    lightshow: <LightShow />,
    blackscreen: <BlackScreen />,
    final: <EndScreen />,
  };
  console.log(step);
  return stepComponents[step];
};

export default StateRouter;
