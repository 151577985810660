const TEAM_SETTINGS = {
  senators: {},
  rocket: {},
  raptors: {
    step: "signup",
    lang: "en-CA",
  },
  torontoultra: {
    step: "signup",
    lang: "en-CA",
  },
  defiant: {
    step: "signup",
    lang: "en-CA",
  },
  stampede: {
    step: "signup",
    lang: "en-CA",
  },
};

export { TEAM_SETTINGS };
