import { configureStore } from "@reduxjs/toolkit";
import { contestStatus } from "./api";

const RESETABLE_STATES = ["home", "signup"];
window.updateUnlocked = true;

const configureAppStore = (hash, namespace, state, reducers) => {
  let preloadedState = state ? { stateMachine: { ...state } } : {};

  const storageKey = `${hash}-${namespace.replace("/", "")}State`;

  if (window.localStorage.getItem(storageKey)) {
    preloadedState = JSON.parse(window.localStorage.getItem(storageKey));
  }

  if (preloadedState?.stateMachine && !preloadedState?.stateMachine?.hash) {
    preloadedState["stateMachine"]["hash"] = hash;
  }
  
  if(!window._RDX){
    window._RDX = configureStore({
      reducer: reducers,
      preloadedState,
    });
  }

  const resetSession = () => {
    window.updateUnlocked = false;
    window.localStorage.removeItem(`${hash}-sessionID`);
    window.localStorage.removeItem(storageKey);
    window.location.reload();
  };

  if (namespace === "/client") {
    contestStatus(hash).then(({ status, round, startedAt }) => {
      const currentRound = parseInt(
        window.localStorage.getItem(`${hash}-round`)
      );

      if (currentRound !== null) {
        if (RESETABLE_STATES.includes(status)) {
          if (currentRound !== round) {
            console.error("Resetting: mismatch round");

            window.localStorage.setItem(`${hash}-round`, round);

            return resetSession();
          }

          if (preloadedState?.stateMachine?.step === "sorry") {
            console.error("Resetting: allowed reset");
            return resetSession();
          }
        }
      }

      if (startedAt === "") {
        startedAt = null;
      }

      window._RDX.dispatch({
        type: "stateMachine/setStarted",
        payload: {
          startedAt,
        },
      });
    });
  }

  window._RDX.subscribe(() => {
    if (window.updateUnlocked) {
      localStorage.setItem(storageKey, JSON.stringify(window._RDX.getState()));
    }
  });

  return window._RDX;
};

export { configureAppStore };
