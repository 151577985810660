
import bgTopLeft from "./../../../../images/deco_up.png"
import I18n from "../../../ui/I18n"
import Logo from "../../Client/pages/helpers/Logo";
import { useSelector } from "react-redux";

const EndScreen = ({ lang }) => {
  const hash = useSelector((state) => state.stateMachine.hash);

  return (
    <>
      <img className="EndScreen__bg" alt="" src={bgTopLeft} />
      <Logo className="EndScreen__logo" />

      <div className="EndScreen__content EndScreen__content">
        <I18n l={lang} t={`jumbotron.end_screen.${hash}.instructions`} />
      </div>

      <div className="EndScreen__content">
        <I18n l={lang} t={`jumbotron.end_screen.${hash}.disclaimer`} />
      </div>
    </>
  )
}

export default EndScreen;
