import { connect } from "react-redux";
import { Manager } from "socket.io-client";

const DEFAULT_CONFIGS = {
  transports: ["websocket"],
  path: '/channel',
  autoConnect: false,
  forceNew: false,
  reconnect: false
};

const DEFAULT_URL = process.env.REACT_APP_API_URL;

const createWebsocket = (namespace, hash, events, additionalParams = {}) => {
  const sessionID = window.localStorage.getItem(`${hash}-sessionID`);
  const round = window.localStorage.getItem(`${hash}-round`);
  const eventChannel = `${hash}-message`;

  if(!window._WSConnection) {
    window._WSConnection =  new Manager(DEFAULT_URL, {
      ...DEFAULT_CONFIGS,
      query: {
        hash: hash
      }
    }).socket(namespace);

    if (sessionID) {
      window._WSConnection.auth = {
        sessionID,
        round,
        ...additionalParams
      };
    } else {
      window._WSConnection.auth = {
        ...additionalParams
      };
    }
  }

  const handleConnect = (e) => {
    console.info(`C #${window._WSConnection.id}`);

    if(events.hasOwnProperty('onConnect')) {
      events.onConnect(window._WSConnection.id);
    }
  };

  const handleDisconnect = (reason) => {
    console.info(`D "${reason}"`);

    if(events.hasOwnProperty('onDisconnect')) {
      events.onDisconnect(reason);
    }
  };

  const handleMessage = (message) => {
    if(events.hasOwnProperty('onMessage')) {
      events.onMessage(message);
    } else {
      console.error('Missing message event callback');
    }
  };

  // Events
  const _rebindEvent = (eventName, fn)=>{
    window._WSConnection.off(eventName);
    window._WSConnection.on(eventName, fn);
  }

  _rebindEvent('connect', handleConnect)
  _rebindEvent('disconnect', handleDisconnect);
  _rebindEvent('connect_error', handleDisconnect);
  _rebindEvent(eventChannel, handleMessage);

  // Actions
  const sendMessage = (type, message) => {
    window._WSConnection.emit(eventChannel, {type, message});
  }

  const dropSession = () => {
    const sessionID = window.localStorage.getItem(`${hash}-sessionID`);

    sendMessage('dropSession', {
      sessionID: sessionID
    });
  }

  const subscribe = () => {
    if(!window._WSConnection.connected) {
      window._WSConnection.connect();
    }
  }

  // direct reconnecting when the sessionID is present
  if(sessionID){
    subscribe();
  }

  window._WSActions = {
    sendMessage,
    subscribe,
    dropSession,
    connect
  }

  return {
    sendMessage,
    subscribe
  }
};

export {
  createWebsocket
};
