import React, { useState } from "react"
import { useDispatch } from "react-redux";
import decoration_image from "./../../../../images/decoration_up.png";
import { participate } from "../stateMachine";
import ModalTermsAndCondition from "./ModalTermsAndCondition"
import I18n from "../../../ui/I18n";
import LoadingIcon from "../../../ui/LoadingIcon";
import Logo from "./helpers/Logo";

const Signup = () => {
  const [connecting, setConnecting] = useState(false)
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const handleClick = () => {
    window._NO_SLEEP.enable();

    dispatch(participate());
    setConnecting(true);
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <img
        className="SignupPage__decoration-image"
        src={decoration_image}
        alt=""
      />

      <Logo className={'SignupPage__logo'} />

      <div className="SignupPage__text">
        <I18n t="client.signup.content"/>
      </div>

      <button
        onClick={handleClick}
        className="button button--long"
        data-action="participate"
        disabled={connecting}
      >
        {
          connecting ?
          (<LoadingIcon />) :
          (<I18n t="client.signup.button"/>)
        }
      </button>

      <button className="SignupPage__rules-link" onClick={openModal} >
        <I18n t="client.signup.link_rules"/>
      </button>

      {showModal && <ModalTermsAndCondition
       closeModal={ closeModal }/>}
    </>
  );
};

export default Signup;
