import { useSelector } from "react-redux";
import I18n from "../../../../ui/I18n";
import playButton from "./../../../../../images/play.svg";

const links = {
  'en-CA': 'https://bell.ca/5G',
  'fr-CA': 'https://www.bell.ca/Mobilite/Le_reseau/5G'
}

const LearnMoreButton = () => {
  const lang = useSelector((state) => state.stateMachine.lang);

  return (
    <a
      href={links[lang]}
      className="button button__icon button--transparent"
    >
      <I18n t='client.learn_more' />

      <img
        className="icon"
        src={playButton}
        alt=""
      />
    </a>
  )
}

export default LearnMoreButton;
