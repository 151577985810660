import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  step: 'home',
  secondsLeft: 5,
  connected: false,
  displayCountdown: true
}

export const stateMachine = createSlice({
  name: 'stateMachine',
  initialState,
  reducers: {
    onMessage: (state, { payload: { type, param } }) => {
      console.log(type, param);

      switch(type){
        case 'state':
          state.step = param;
          break;

        case 'countdown':
          state.secondsLeft = param;
          break;

        default:
          return;
      }
    },
    onDisconnection: (state, { payload }) => {
      state.connected = false;

      window._WSConnection.io.connect();
    },
    onConnection: (state, { payload }) => {
      state.connected = true;
    },
    setState: (state, { payload }) => {
      state.step = payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  onMessage,
  onDisconnection,
  onConnection,
  setState
} = stateMachine.actions;

export const actions = stateMachine.actions;

export default stateMachine.reducer;
