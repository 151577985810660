import { useSelector } from "react-redux";

import logoFr from "../../../../../images/logos/logo-fr.svg";
import logoEn from "../../../../../images/logos/logo-en.svg";

const logo = {
  'en-CA': logoEn,
  'fr-CA': logoFr
};

const Logo = ({ className })=>{
  const lang = useSelector((state) => state.stateMachine.lang);

  return (
    <img
      className={`Logo ${className}`}
      src={logo[lang]}
      alt="Logo Bell"
    />
  )
}

export default Logo;
