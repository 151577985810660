import rocket from "./../../../../../images/presentations/rocket.mp4";
import senators from "./../../../../../images/presentations/senators.mp4";
import raptors from "./../../../../../images/presentations/raptors.mp4";
import torontoultra from "./../../../../../images/presentations/torontoultra.mp4";
import defiant from "./../../../../../images/presentations/defiant.mp4";
import stampede from "./../../../../../images/presentations/stampede.mp4";
import { useSelector } from "react-redux";

const videos = {
  raptors: raptors,
  senators: senators,
  rocket: rocket,
  torontoultra: torontoultra,
  stampede: stampede,
  defiant: defiant
};

const PresentationVideo = ({ onVideoEnd }) => {
  const hash = useSelector((state) => state.stateMachine.hash);

  return (
    <video
      className="Presentation__video"
      autoPlay={true}
      muted={true}
      onEnded={onVideoEnd}
    >
      <source src={videos[hash]} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
};

export default PresentationVideo;
