import I18n from '../../../../ui/I18n';

const Slogan = () => {
  return (
    <div className="text text--bold">
      <I18n t='client.slogan' />
    </div>
  )
}

export default Slogan;
