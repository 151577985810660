import { useState } from "react";

import decorationDown from "./../../../../images/deco_down.png";
import I18n from "../../../ui/I18n";
import Logo from "../../Client/pages/helpers/Logo";
import PresentationVideo from "./helpers/PresentationVideo";
import ContestQR from "./helpers/ContestQR";

const Singup = ({ hash, lang }) => {
  const [showVideo, setShowVideo] = useState(true);

  const handleVideoEnd = () => {
    setShowVideo(false);
  };

  const renderVideo = () => {
    return <PresentationVideo onVideoEnd={handleVideoEnd} hash={hash} />;
  };

  const renderSignup = () => {
    return (
      <div className="SignupPage__content">
        <img
          className="SignupPage__decoration"
          src={decorationDown}
          alt=""
        />

        <div className="SignupPage__column">
          <Logo className="SignupPage__logo" />

          <div className="SignupPage__instructions">
            <I18n t="jumbotron.signup.instructions" />
          </div>
        </div>

        <div className="SignupPage__column">
          <div className="SignupPage__qr">
            <ContestQR />
          </div>
        </div>

      </div>
    );
  };

  return showVideo ? renderVideo() : renderSignup();
};

export default Singup;
