import { useSelector } from "react-redux";
import I18n, { translate } from "../../../ui/I18n";
import Modal from "../../../ui/Modal";
const sufix = ["th", "st", "nd", "rd"];

const formarOrdinal = (number) => {
  const index = number % 100;

  return number + (sufix[(index - 20) % 10] || sufix[index] || sufix[0]);
};

export const ModalTermsAndCondition = ({ closeModal }) => {
  const lang = useSelector((state) => state.stateMachine.lang);
  const hash = useSelector((state) => state.stateMachine.hash);

  const date = new Date();

  const params = {
    day: lang === "en-CA" ? formarOrdinal(date.getDate()) : date.getDate(),
    month: translate(lang, `months.${date.getMonth() + 1}`),
    year: date.getFullYear(),
  };

  return (
    <Modal
      onClose={closeModal}
      title={<I18n t={`client.rules.${hash}.title`} />}
    >
      <p>
        <I18n t={`client.rules.${hash}.content`} params={params} />
      </p>
    </Modal>
  );
};

export default ModalTermsAndCondition;
