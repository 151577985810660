
import { useDispatch, useSelector } from 'react-redux'
import {
  sendMessage
} from '../stateMachine';
import Timer from './Timer';
import I18n from '../../../ui/I18n';

import "./actions.scss"
import { useState } from 'react';
import Modal from '../../../ui/Modal/Modal';


const DEV_MODE = process.env.NODE_ENV !== 'production'



const Actions = () => {
  const step = useSelector(state => state.stateMachine.step);
  const timer = useSelector(state => state.stateMachine.timer);
  const [showResetConfirm, setShowResetConfirm] = useState(false)
  const dispatch = useDispatch();

  const BUTTONS = [{
    action: 'startExperience',
    allowedState: ['home']
  },{
    action: 'reset',
    onClick: ()=>{
      setShowResetConfirm(true)
    },

    allowedState: ['final', 'home'],
    classNames: 'button--red'
  }]

  const emitAction = (action)=>{
    console.log(action)
    dispatch(sendMessage({
      type: action,
      message: {}
    }));
  }

  const renderButton = ({ onClick, action, allowedState, classNames = ""}) => {
    if(!allowedState.includes(step)){ return }

    const onEvent = ()=>{
      if(onClick){
        onClick()
      } else{
        emitAction(action)
      }
    }
    return(
      <button
        key={action}
        className={`button button--long state ${classNames }`}
        onClick={onEvent}
        data-action={action}
        disabled={!allowedState.includes(step)}
      >
        <I18n l='en-CA' t={`admin.action_buttons.${action}`} />
      </button>
    )
  }

  const renderResetConfirm = () => {
    const buttons = [{
      label:'Cancel',
      onClick: () => {
        setShowResetConfirm(false);
      }
    },{
      label:'Reset',
      className: 'button--red',
      onClick: () => {
        dispatch(sendMessage({
          type: 'reset',
          message: {}
        }));

        setShowResetConfirm(false);
      }
    }]
    return (
      <Modal buttons={buttons} className="Admin__confirm">
        <h3>Make sure the prize has been redeemed before resetting</h3>
      </Modal>
    )
  }


  const renderDEVAction = ()=>{
    return (
      <div className="actions-inside">
        <h4>DEV ACTIONS</h4>

        <button className="button button--short state" onClick={emitAction.bind(null, 'allWinner')} data-action="allWinner">Everyone wins</button>
        <button className="button button--short state" onClick={emitAction.bind(null, 'allLoser')} data-action="allLoser">Everyone loses</button>
        <button className="button button--short state" onClick={emitAction.bind(null, 'performSort')} data-action="performSort">Perform Sort</button>
        <button className="button button--short state" onClick={emitAction.bind(null, 'reset')} data-action="reset">Reset</button>
      </div>
    )
  }

  return (
    <div className="actions">
      {timer && <Timer />}
      {showResetConfirm && renderResetConfirm() }

      {BUTTONS.map(renderButton)}

      {DEV_MODE && renderDEVAction()}
    </div>
  );
}

export default Actions
