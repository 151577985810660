import ProviderWS from "../../Provider";
import stateMachine, {
  initialState,
  onMessage,
  onConnection,
  onDisconnection,
} from "./stateMachine";
import Page from "../../ui/Page";

import StateRouter from "./StateRouter";

import { TEAM_SETTINGS } from "./teamSettings";
import ConnectionIndicator from "../../ui/ConnectionIndicator";

// window.updateUnlocked = false;

const Jumbotron = ({
  match: {
    params: { hash, overlay },
  },
}) => {
  const state = {
    ...initialState,
    ...TEAM_SETTINGS[hash],
    // step: 'lightshow'
  };

  return (
    <Page className="Jumbotron">
      <ProviderWS
        namespace="/jumbotron"
        state={state}
        hash={hash}
        autoConnect={true}
        reducers={stateMachine}
        onMessage={onMessage}
        onConnection={onConnection}
        onDisconnection={onDisconnection}
      >
        <StateRouter />

        <div className="Jumbotron__connection-indicator">
          <ConnectionIndicator hidePositive={true} />
        </div>
      </ProviderWS>

      <div className="full-window full-window--overlay" />
    </Page>
  );
};

export default Jumbotron;
