import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decreaseTimer } from "../stateMachine";

const Timer = () => {
  const dispatch = useDispatch();

  const timerDuration = useSelector(state => state.stateMachine.timerDuration);
  const timerMessage = useSelector(state => state.stateMachine.timerMessage);

  const decTimer = () => dispatch(decreaseTimer())

  useEffect(() => {
    if(!window.controlTimer) {
      window.controlTimer = setInterval(() => {
        decTimer();
      }, 1000);
    }
  });

  return (
    <div className="Timer">
      <button
        className={`button button--long state`}
        disabled={true}
      >
        {timerMessage}: {timerDuration}
      </button>
    </div>
  )
}

export default Timer;
