import I18n from '../../../../ui/I18n';
import prize from "../../../../../images/prizes/defiant.png";
import Slogan from '../helpers/Slogan';
import LearnMoreButton from '../helpers/LearnMoreButton';

const WinnerDefiant = () => {
  return (
    <>
      <div className="WinnerPage__sub-title">
        <I18n t="client.winner.defiant.win_text" />
      </div>

      <img
        className="WinnerPage__prize"
        src={prize}
        alt=""
      />

      <div className="WinnerPage__instructions">
        <I18n t="client.winner.defiant.instructions" />
      </div>
      <Slogan />

      <LearnMoreButton />
    </>
  )
}

export default WinnerDefiant;
