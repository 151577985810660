
const BlackScreen = ()=>{
  return (
    <div className="BlackScreen">

    </div>
  )
}

export default BlackScreen;
