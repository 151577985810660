import { createSlice } from "@reduxjs/toolkit";
import { offlineState } from "./offline";

export const initialState = {
  step: "home", // default: home
  lang: 'en-CA', // default: null
  secondsLeft: 5,
  sessionID: null,
  lastMessageAt: null,
  connected: false,
  hash: null,
  focused: true,
  startedAt: null,
};

window._attachedEvents = false;

const RECONNECT_CASES = [
  "io client disconnect",
  "forced close",
  "focus",
  "transport close",
  "transport error",
  "Error: websocket error",
];

const PRIORITY_STATES = [
  "home",
  "signup",
  "waiting",
  "countdown",
  "lightshow",
  "blackscreen",
  "winnerslight",
  "winner",
  "loser",
  "sorry",
];

const shouldUpdateState = (currentStep, newStep) => {
  return (
    PRIORITY_STATES.indexOf(currentStep) < PRIORITY_STATES.indexOf(newStep)
  );
};

const shouldReconnect = (state, payloadMessage) => {
  const { hash, sessionID, focused } = state;

  let lightShowReconnect = true;

  if (state.step === "lightshow") {
    const elapsedTime = new Date() - new Date(state.lastMessageAt);

    if (elapsedTime > 14000) {
      lightShowReconnect = false;
    }
  }

  return (
    hash &&
    focused &&
    sessionID &&
    lightShowReconnect &&
    RECONNECT_CASES.includes(payloadMessage)
  );
};

export const stateMachine = createSlice({
  name: "stateMachine",
  initialState,
  reducers: {
    onMessage: (state, { payload: { type, param, timestamp } }) => {
      console.log(timestamp, type, param);

      switch (type) {
        case "state":
          if (state.step !== param) {
            state.lastMessageAt = timestamp;
          }

          if (shouldUpdateState(state.step, param)) {
            state.step = param;
          }
          break;
        case "countdown":
          state.secondsLeft = param;
          break;
        case "session":
          const { sessionID, userID, hash } = param;

          window.localStorage.setItem(`${hash}-sessionID`, sessionID);

          window._WSConnection.auth = { sessionID };
          window._WSConnection.userID = userID;

          state.sessionID = sessionID;
          state.hash = hash;

          break;
        default:
          return;
      }
    },
    setStarted: (state, { payload: { startedAt } }) => {
      state.startedAt = startedAt;
    },
    onDisconnection: (state, { payload }) => {
      state.connected = false;

      // Reconnection rules
      if (shouldReconnect(state, payload.message)) {
        setTimeout(() => {
          window._WSActions.subscribe();
        }, 300);
      }
    },
    onConnection: (state, { payload }) => {
      state.connected = true;
    },
    onFocus: (state, { payload }) => {
      state.focused = true;

      // Reconnection rules
      if (shouldReconnect(state, "focus")) {
        window._WSActions.subscribe();
      }
    },
    onBlur: (state, { payload }) => {
      state.focused = false;

      setTimeout(() => {
        window._WSConnection.disconnect();
      }, 200);
    },
    setState: (state, { payload }) => {
      if (shouldUpdateState(state.step, payload)) {
        state.step = payload;
      }
    },
    setLang: (state, { payload }) => {
      state.lang = payload;
    },
    setSecondsLeft: (state, { payload }) => {
      state.secondsLeft = payload;
    },
    participate: (state, action) => {
      window._WSActions.subscribe();
    },
    emitTick(state, { payload }) {
      if (!state.connected && state.startedAt) {
        const nextStep = offlineState(state.step, state.startedAt);

        if (nextStep) {
          state.step = nextStep;
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onMessage,
  onDisconnection,
  onConnection,
  setState,
  setLang,
  setSecondsLeft,
  participate,
  onFocus,
  onBlur,
  emitTick,
} = stateMachine.actions;

export const actions = stateMachine.actions;

export default stateMachine.reducer;
