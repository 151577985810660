import React from "react"
import I18n from "../../../ui/I18n";

const Sorry = () => {

  return (
    <>
      <div className="SorryPage__title">
        <I18n t="client.sorry.title" />
      </div>

      <div className="SorryPage__sub-title">
        <I18n t="client.sorry.text" />
      </div>
    </>
  )
}

export default Sorry;
