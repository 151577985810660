import {
  setState,
  setLang
} from '../stateMachine';

import logo from "./../../../../images/logos/logo-full.svg"

import decoration_image from "./../../../../images/decotration_down.png"

import { useDispatch } from 'react-redux'

const Home = () => {
  const dispatch = useDispatch();

  const handleClick = (lang) => {
    dispatch(setLang(lang));
    dispatch(setState('signup'));
  }

  return (
    <>
      <img
        className="HomePage__decoration"
        src={decoration_image}
        alt=""
      />
      <img
        className="HomePage__logo"
        src={logo}
        alt="Logo Bell"
      />

      <div className="HomePage__buttons">
        <button
          className="button button--long"
          onClick={handleClick.bind(null, 'fr-CA')}
          data-lang="fr-CA"
        >
          Français
        </button>

        <button
          className="button button--long"
          onClick={handleClick.bind(null, 'en-CA')}
          data-lang="en-CA"
        >
          English
        </button>
      </div>
    </>
  )
}

export default Home;
