import { useSelector } from "react-redux";
import WinnerRaptors from "./winners/Raptors";
import WinnerSenators from "./winners/Senators";
import WinnerRocket from "./winners/Rocket";
import WinnerTorontoultra from "./winners/Torontoultra";
import WinnerStampede from "./winners/Stampede";
import WinnerDefiant from "./winners/Defiant";
import I18n from "../../../ui/I18n";

const pages = {
  "raptors": <WinnerRaptors />,
  "senators": <WinnerSenators />,
  "rocket": <WinnerRocket />,
  "torontoultra": <WinnerTorontoultra />,
  "stampede": <WinnerStampede />,
  "defiant": <WinnerDefiant />,
  "default": <WinnerRocket />
}

const Winner = () => {
  let hash = useSelector(state => state.stateMachine.hash);

  return (
    <>
      <div className="WinnerPage__title">
        <I18n t={`client.winner.${hash}.title`} />
      </div>

      {pages[hash] || pages['default']}

    </>
  )
}

export default Winner;
