import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import I18n from '../../../ui/I18n';
import { setSecondsLeft, setState } from '../stateMachine';

const Countdown = ()=>{
  const dispatch = useDispatch();
  const secondsLeft = useSelector(state => state.stateMachine.secondsLeft);
  const hash = useSelector(state => state.stateMachine.hash);
  let connected = useSelector(state => state.stateMachine.connected);

  useEffect(()=>{
    if(!connected){
      setTimeout(()=>{
        if(secondsLeft -1 === 0){
          dispatch(setState('lightshow'));
        } else {
          dispatch(setSecondsLeft(secondsLeft -1))
        }
      }, 1000);
    }
  })
  return (
    <>
      <div className="CountdownPage__title">
        <I18n t={`client.countdown.${hash}.title`} />
      </div>

      <div className="CountdownPage__number">
        {secondsLeft}
      </div>
    </>
  )
}

export default Countdown;
