import { useState } from "react";
import ProviderWS from "../../Provider";
import stateMachine, {
  onMessage,
  onConnection,
  onDisconnection,
} from "./stateMachine";
import Actions from "./pages/Actions";
import Stats from "./pages/Stats";
import Page from "../../ui/Page";
import "./admin.scss";
import ConnectionIndicator from "../../ui/ConnectionIndicator";
import ConnectionOverlay from "../../ui/ConnectionOverlay";

const Admin = ({
  match: {
    params: { hash },
  },
}) => {
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(false);

  const authenticate = () => {
    window._NO_SLEEP.enable();

    setAuthenticated(true);
  };

  const renderLogin = () => {
    return (
      <Page className="Admin">
        <div className="Admin__header">Admin</div>
        <form className="Admin__login" onSubmit={authenticate}>
          <h2 className="Admin__login-title">Login</h2>

          <div>
            <input
              className="Admin__login-password"
              name="search_password"
              required="required"
              type="password"
              placeholder="password"
              value={password}
              onInput={({ target: { value } }) => {
                setPassword(value);
              }}
            />
          </div>

          <button className="button button--long" type="submit">
            Login
          </button>
        </form>
      </Page>
    );
  };

  const renderProvider = () => {
    return (
      <ProviderWS
        namespace="/admin"
        hash={hash}
        reducers={stateMachine}
        autoConnect={true}
        onMessage={onMessage}
        onConnection={onConnection}
        onDisconnection={onDisconnection}
        additionalParams={{ password }}
      >
        <Page className="Admin">
          <div className="Admin__header">
            <ConnectionIndicator />
            Admin
          </div>
          <ConnectionOverlay />
          <Stats />
          <Actions />
        </Page>
      </ProviderWS>
    );
  };

  return authenticated ? renderProvider() : renderLogin();
};

export default Admin;
