import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  step: 'home',
  stats: [],
  connections: 0,
  winners: [],
  connected: false,
  timer: false,
  timerDuration: 0,
  timerMessage: ''
}

export const stateMachine = createSlice({
  name: 'stateMachine',
  initialState,
  reducers: {
    onMessage: (state, { payload }) => {
      switch(payload.type){
        case 'stats':
          state.stats = payload['stats']
          state.step = payload['step']
          state.connections = payload['connections']

          if(payload['step'] === 'final' ) {
            state.timer = false
            // clearInterval(window.controlTimer);
          }

          break;
        case 'timer':

          state.timer = true;
          state.timerDuration = parseInt(payload['duration']) / 1000;
          state.timerMessage = payload['message'];

          break;
        case 'winners':
          state.winners = payload['winners'];

          break;
        default:
          console.log(`Unsuported: ${payload.type}`);
      }
    },
    onDisconnection: (state, params) => {
      state.connected = false

      window._WSConnection.io.connect();
    },
    onConnection: (state, params) => {
      state.connected = true
    },
    sendMessage(state, { payload: { type, message } }){
      window._WSActions.sendMessage(type, message);

      state.timerDuration = 0;
      state.timer = false;
      // clearInterval(window.controlTimer);
    },
    decreaseTimer(state, { payload }){
      if(state.timerDuration > 0){
        state.timerDuration--;
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  onMessage,
  onDisconnection,
  onConnection,
  sendMessage,
  decreaseTimer
} = stateMachine.actions;

export const actions = stateMachine.actions;

export default stateMachine.reducer;
