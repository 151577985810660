import { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "./Modal/Modal";

const ConnectionOverlay = () => {
  const connected = useSelector((state) => state.stateMachine.connected);

  const [show, setShow] = useState(false);

  clearTimeout(window._connection_overlay);

  if (!connected) {
    window._connection_overlay = setTimeout(() => setShow(true), 1000);
  }

  if (show) {
    return (
      <Modal className="flex flex--middle">
        <h3>
          You are disconnected!
          <br />
          Refresh the page
        </h3>
      </Modal>
    );
  }

  return <></>;
};

export default ConnectionOverlay;
