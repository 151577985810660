import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setState } from "../stateMachine";
import lightshowVideo from "./../../../../images/lightshowVideo.mp4";
import I18n from "../../../ui/I18n";

const TOTAL_SECONDS = 30;

const LightShow = ({ lang }) => {
  const hash = useSelector((state) => state.stateMachine.hash);
  const dispatch = useDispatch();
  const [leftTime, setLeftTime] = useState(TOTAL_SECONDS);

  useEffect(() => {
    if (leftTime > 0) {
      setTimeout(() => {
        setLeftTime(leftTime - 1);
      }, 1000);
    } else {
      dispatch(setState("final"));
    }
  });

  return (
    <>
      <div className="Lightshow">
        <video className="Lightshow__player" autoplay="" muted="true">
          <source src={lightshowVideo} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>

      <div className="Lightshow__bar">
        <div className="Lightshow__bar-text">
          <I18n t={`jumbotron.countdown.${hash}.instructions`} />
        </div>
      </div>
    </>
  );
};

export default LightShow;
