import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { emitTick, onFocus, onBlur } from './stateMachine';

import BlackScreen from './pages/BlackScreen';
import Home from './pages/Home';
import Signup from './pages/Signup';
import Waiting from './pages/Waiting';
import LightShow from './pages/LightShow';
import Countdown from './pages/Countdown';
import Sorry from './pages/Sorry';
import Winner from './pages/Winner';
import Loser from './pages/Loser';
import WinnersLight from './pages/WinnersLight';

const StateRouter = ({ subscribe }) => {
  const dispatch = useDispatch();

  let step = useSelector(state => state.stateMachine.step);
  const lang = useSelector(state => state.stateMachine.lang);

  const timerTick = () => dispatch(emitTick())

  if(!lang) {
    step = 'home';
  }

  useEffect(() => {
    if(!window._attachedEvents) {
      window.addEventListener('blur', () => dispatch(onBlur()));
      window.addEventListener('focus', () => dispatch(onFocus()));
      window._attachedEvents = true;
    }

    if(!window.controlTimer) {
      window.controlTimer = setInterval(() => {
        timerTick();
      }, 800);
    }
  });

  const stepComponents = {
    'home': <Home />,
    'signup': <Signup subscribe={subscribe} />,
    'waiting': <Waiting />,
    'countdown': <Countdown />,
    'lightshow': <LightShow />,
    'blackscreen': <BlackScreen />,
    'winner': <Winner />,
    'loser': <Loser />,
    'sorry': <Sorry />,
    'winnerslight': <WinnersLight />
  };

  return stepComponents[step];
}

export default StateRouter;
