import { useEffect } from "react";
import { Provider } from "react-redux";

import { createWebsocket } from "../../configs/socket";
import { configureAppStore } from "../../configs/store";

import Debug from "./debug";

const DEV_MODE =
  new URLSearchParams(document.location.search).get("debug") === "true";
const LOGS =
  new URLSearchParams(document.location.search).get("logs") === "true";

const ProviderWS = ({
  autoConnect,
  state,
  namespace,
  reducers,
  onMessage,
  onConnection,
  onDisconnection,
  hash,
  additionalParams,
  children,
}) => {
  const store = configureAppStore(hash, namespace, state, {
    stateMachine: reducers,
  });

  const events = {
    onMessage(message) {
      store.dispatch(onMessage(message));
    },
    onConnect(message) {
      store.dispatch(onConnection({ hash, message }));
    },
    onDisconnect(message) {
      store.dispatch(onDisconnection({ hash, message }));
    },
  };

  const { subscribe } = createWebsocket(
    namespace,
    hash,
    events,
    additionalParams
  );

  // establish connection when component monted
  useEffect(() => {
    autoConnect && subscribe();
  });

  return (
    <Provider store={store}>
      {children}

      {DEV_MODE && (
        <Debug hash={hash} namespace={namespace} logs={LOGS} />
      )}
    </Provider>
  );
};

export default ProviderWS;
