import I18n from "../../../ui/I18n";
import phone from "./../../../../images/phone-on-pedestal.png";

const Waiting = () => {

  return (
    <>
      <div className="WaitingPage__title">
        <I18n t="client.waiting.title" />
      </div>

      <div className="WaitingPage__text">
        <I18n t="client.waiting.content" />
      </div>

      <img
        className="WaitingPage__image"
        src={phone}
        alt=""
      />
    </>
  );
};

export default Waiting;
