import { useSelector } from 'react-redux'

const channelLabels = {
  'client': "Clients connected",
  'admin': "Admin connected",
  'jumbotron':"Jumbotron connected",
}

const Stats = () => {
  const stats = useSelector(state => state.stateMachine.stats);
  const step = useSelector(state => state.stateMachine.step);
  // const winners = useSelector(state => state.stateMachine.winners);
  const connections = useSelector(state => state.stateMachine.connections);

  const renderStats = (stat)=>{
    const [namespace, connections] = stat;
    let titlespace = channelLabels[namespace] || "";

    return (
      <div
        className="stats-stat-box-onebyone__stat"
        key={namespace}
        data-namespace={namespace}
        data-sessions={connections}
      >
        <p className="stats-stat-box-onebyone__stat__title__stat">{titlespace}:</p>
        <p className="stats-stat-box-onebyone__stat__result__stat">&nbsp;{connections}</p>
      </div>
    );
  };

  return (
    <div className="stats">
      <div className="stats-box">
        <div className="stats-stat-box-onebyone__stat__title__stat current">
          <p className="stats-stat-box__onebyone__stat__title__stat">Current State: {step}</p>
          {connections}
        </div>
      </div>
      <div className="stat-box">{stats.map(renderStats)}</div>
    </div>
  );
}

export default Stats;
