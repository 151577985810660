import decorationUp from "./../../../../images/deco_up.png";
import I18n from "../../../ui/I18n";
import Logo from "../../Client/pages/helpers/Logo";

const Home = () => {
  return (
    <>
      <img
        className="HomePage__decoration"
        src={decorationUp}
        alt=""
      />

      <Logo className="HomePage__logo" />

      <div className="HomePage__instructions">
        <I18n t="jumbotron.home.instructions" />
      </div>
    </>
  );
};

export default Home;
