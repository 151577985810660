import telephone from "./../../../../images/telephone.png";
import I18n from "../../../ui/I18n";
import LearnMoreButton from "./helpers/LearnMoreButton";
import Slogan from "./helpers/Slogan";

const Loser = () => {

  return (
    <>
      <div className="LoserPage__title">
        <I18n t='client.loser.title' />
      </div>

      <div className="LoserPage__sub-title">
        <I18n t='client.loser.content' />
      </div>

      <img
        className="LoserPage__image"
        src={telephone}
        alt=""
      />


      <Slogan />
      <LearnMoreButton />
    </>
  )
}

export default Loser;
