import ProviderWS from "../../Provider";
import stateMachine, {
  initialState,
  onMessage,
  onConnection,
  onDisconnection,
} from "./stateMachine";
import PageRedux from "../../ui/Page/PageRedux";

import StateRouter from "./StateRouter";
import { TEAM_SETTINGS } from "./teamSettings";

// window.updateUnlocked = false;

const Client = ({
  match: {
    params: { hash },
  },
}) => {
  const state = {
    ...initialState,
    ...TEAM_SETTINGS[hash],
    // step: 'winner',
    // lang: 'fr-CA'
  };

  return (
    <ProviderWS
      namespace="/client"
      state={state}
      hash={hash}
      autoConnect={false}
      reducers={stateMachine}
      onMessage={onMessage}
      onConnection={onConnection}
      onDisconnection={onDisconnection}
    >
      <PageRedux className="Client" hash={hash}>
        <StateRouter />
      </PageRedux>
    </ProviderWS>
  );
};

export default Client;
