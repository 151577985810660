import { useSelector } from "react-redux";
import I18n from "../../../ui/I18n";
import iphoneBack from "./../../../../images/iphone.png";
import iphoneFront from "./../../../../images/iphone1.png";
import turnarrow from "./../../../../images/turnarrow.svg";
import decorationRight from "./../../../../images/verticaltriangle.png";

const Countdown = () => {
  const secondsLeft = useSelector((state) => state.stateMachine.secondsLeft);
  const hash = useSelector((state) => state.stateMachine.hash);

  return (
    <>
      <div className="CountdownPage__title">
        <I18n t={`jumbotron.countdown.${hash}.instructions`} />
      </div>

      <div className="CountdownPage__content">
        <div className="CountdownPage__column">
          <img
            className="CountdownPage__image-phone"
            src={iphoneFront}
            alt=""
          />
          <img
            className="CountdownPage__image-arrow"
            src={turnarrow}
            alt=""
          />
        </div>

        <div className="CountdownPage__column">
          <p className="CountdownPage__number">{secondsLeft}</p>
        </div>

        <div className="CountdownPage__column">
          <img
            className="CountdownPage__image-phone"
            src={iphoneBack}
            alt=""
          />

          <img
            className="CountdownPage__image-arrow"
            src={turnarrow}
            alt=""
          />
        </div>
      </div>
      <img className="CountdownPage__decoration" src={decorationRight} alt="" />
    </>
  );
};

export default Countdown;
