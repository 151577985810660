const TEAM_SETTINGS = {
  senators: {
    lang: "en-CA",
  },
  rocket: {
    lang: "fr-CA",
  },
  raptors: {
    lang: "en-CA",
  },
  torontoultra:{
    lang: "en-CA"
  },
  defiant:{
    lang: "en-CA"
  },
  stampede:{
    lang: "en-CA"
  }
};

export { TEAM_SETTINGS };
