
import I18n from '../../../ui/I18n';

const BlackScreen = ()=>{

  return (
    <>
      <div className="BlackscreenPage__text">
        <I18n t="client.blackscreen.disclaimer"/>
      </div>
    </>
  )
}

export default BlackScreen;
