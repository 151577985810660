import QRCode from "qrcode.react";
import { useSelector } from "react-redux";

const ContestQR = () => {
  const hash = useSelector((state) => state.stateMachine.hash);
  const contestUrl = new URL(`/#/${hash}`, window.location.href).toString();

  return (
    <QRCode
      width="880"
      height="880"
      value={contestUrl}
      renderAs="svg"
      level="H"
    />
  );
};

export default ContestQR;
