import I18n from '../../../../ui/I18n';
import prize from "../../../../../images/prizes/rocket.png";

const WinnerSenators = () => {
  return (
    <>
      <div className="WinnerPage__sub-title">
        <I18n t="client.winner.rocket.win_text" />
      </div>

      <img
        className="WinnerPage__prize"
        src={prize}
        alt=""
      />

      <div className="WinnerPage__instructions">
        <I18n t="client.winner.rocket.instructions" />
      </div>
    </>
  )
}

export default WinnerSenators;
